import { ResFetch2URL, getImageInfo, resURLConcatenation } from "@/function/Network";
import React, { PureComponent } from "react";
import "./index.less"





type RButtonConfig = {
    debug?: boolean
}

export const RButtonConfig = React.createContext<RButtonConfig>({});

type RBackgroundProps = {
    children?: React.ReactNode | React.ReactNode[] | string | string[]
    hover?: {
        url: string
        pos: [top: number, left: number,]
        size?: [width: number, height: number]
    }
    background?: string
    pos: [top: number, left: number,]
    size: [width: number, height: number]
    radius?: [左上: number, 右上: number, 右下: number, 左下: number] | number | "max"
    onClick?: () => void
    tag?: string
}


type RBackgroundState = {
    colorKey?: number
    background?: URL
    hover?: URL
}
export class RButton extends PureComponent<RBackgroundProps, RBackgroundState> {
    timer?: NodeJS.Timer;
    constructor(props: RBackgroundProps) {
        super(props);
        this.state = {
            colorKey: 0,
        };
    }

    render() {
        const className = ["RButton"]
        if (this.props.tag) {
            className.push(this.props.tag)
        }
        let radius = Array.isArray(this.props.radius) ? this.props.radius : (this.props.radius === "max" ? [9999999] : [this.props.radius])

        return <RButtonConfig.Consumer>
            {CONFIG => {
                const debug = !!CONFIG.debug
                return <div className={className.join(" ")} style={{
                    position: "absolute",
                    top: this.props.pos[0],
                    left: this.props.pos[1],
                    width: this.props.size[0],
                    height: this.props.size[1],
                    borderRadius: radius.join("px ") + "px",
                    overflow: "visible",
                    cursor: this.props.onClick ? "pointer" : undefined,
                    backgroundColor: debug ? "#ff000057" : this.props.background,
                    backgroundImage: `url(${this.state.background?.href})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                }} onClick={this.props.onClick} >
                    <div className={"hover"} style={{
                        position: "absolute",
                        top: this.props.hover && this.props.hover.pos ? this.props.hover.pos[0] : 0,
                        left: this.props.hover && this.props.hover.pos ? this.props.hover.pos[1] : 0,
                        width: this.props.hover && this.props.hover.size ? this.props.hover.size[0] : this.props.size[0],
                        height: this.props.hover && this.props.hover.size ? this.props.hover.size[1] : this.props.size[1],
                        borderRadius: radius.join("px ") + "px",
                        cursor: this.props.onClick ? "pointer" : undefined,
                        backgroundImage: `url(${this.state.hover?.href || this.state.background?.href})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                    }}>
                    </div>
                    {this.props.children ? <div className={"RButton-children"} style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                    }}>
                        {this.props.children}
                    </div> : ""}


                </div>
            }}
        </RButtonConfig.Consumer>
    }
    async componentDidMount() {
        if (this.props.background) {
            const background = await resURLConcatenation(this.props.background, true)
            this.setState({ background })
        }

        if (this.props.hover?.url) {
            const hover = await resURLConcatenation(this.props.hover.url, true)
            this.setState({ hover })
        }
    }
}
