
import { BackTop } from '@/component/BackTop';
import { RButtonConfig } from '@/component/RBackground/button';
import { RTextConfig } from '@/component/RBackground/text';
import { getOverflowStyles } from '@/function/CompatibilityFix';
import { DeviceDetector } from '@/function/DeviceDetector';
import { Anchor } from 'antd';
import 'antd/dist/antd.min.css';
import { ReactWarn2Warn } from 'fuck-react-warning';
import React, { PureComponent } from 'react';
import "./drupal.less";
import "./global.less";
import "./index.less";
import { Check } from './sections/check';
import { Head } from './sections/head';
import { HKCode } from './sections/hk';
import { Menu } from './sections/menu';
import { Section1, Section2, Section3, Section4, Section5 } from './sections/normal';
import { SectionQuiz } from './sections/quiz';
import { Quiz } from './sections/quiz/modal';
import { SectionReference } from './sections/reference';
import { Video } from './sections/video';

const { Link } = Anchor;


const Device = new DeviceDetector()
export const DeviceInfoContext = React.createContext(Device.info);

type TopState = {
  device: typeof Device.info
  showCheck: boolean
  showQuiz: boolean
}
export type AnchorIds = "video" | "quiz" | "section1" | "section2" | "section3"

let debug = false
// debug = true
class Page extends PureComponent<{}, TopState> {
  constructor({ }) {
    super({})
    this.state = {
      device: Device.info,
      showCheck: false,
      showQuiz: false,
    }
  }

  render() {
    return <DeviceInfoContext.Provider value={this.state.device}>
      <RButtonConfig.Provider value={{ debug }}>
        <RTextConfig.Provider value={{ debug }}>
          <div className="entrance">
            <Head onCheckClicked={this.switchCheckShowState.bind(this)} />
            <Menu onCheckClicked={this.switchCheckShowState.bind(this)} scrollToSection={this.scrollToSection.bind(this)} />
            <SectionQuiz tag='quiz' onQuizClicked={this.switchQuizShowState.bind(this)} />
            <Video tag={"video"} />

            <Section1 tag="section1" />
            <Section2 tag="section2" />
            <Section3 tag="section3" />
            <Section4 tag="section4" />
            <Section5 tag="section5" onCheckClicked={this.switchCheckShowState.bind(this)} />
            <SectionReference tag="reference" />
            <HKCode tag="HK-7832 07/10/2022" />

            <Quiz show={this.state.showQuiz} onClose={this.switchQuizShowState.bind(this)} onCheckClicked={this.switchCheckShowState.bind(this)} />
            <Check show={this.state.showCheck} onClose={this.switchCheckShowState.bind(this)} />





            <BackTop />







            <Anchor className='anchor-instance' style={{
              position: "fixed", top: 0,
              display: "none"
            }}>
              <Link href="#video" title="video" />
              <Link href="#quiz" title="quiz" />
              <Link href="#section1" title="section1" />
              <Link href="#section2" title="section2" />
              <Link href="#section3" title="section3" />
              <Link href="#section4" title="section4" />
              <Link href="#section5" title="section5" />
            </Anchor>

          </div>
        </RTextConfig.Provider>
      </RButtonConfig.Provider>
    </DeviceInfoContext.Provider>
  }
  componentDidMount() {
    Device.onChange = (info) => { this.setState({ device: info }) }
    this.compatibilityFix()
    this.checkHash()
    this.drupalFix()
  }
  getDeviceInfo() {
    return new DeviceDetector()
  }
  switchCheckShowState() {
    this.setState({ showCheck: !this.state.showCheck })
  }
  switchQuizShowState() {
    this.setState({ showQuiz: !this.state.showQuiz })
  }
  scrollToSection(tag: AnchorIds) {
    const anchor = document.getElementsByClassName("anchor-instance")[0]
    if (anchor) {
      const as = anchor.getElementsByTagName("a")
      for (let element of as) {
        if (element.getAttribute("href") === `#${tag}`) {
          element.click()
          break
        }
      }
    }

  }
  compatibilityFix() {
    document.body.style.overflowY = getOverflowStyles("overlay")
  }
  checkHash() {
    if (new URL(document.location.href).hash === "#turnonbooking") {
      this.setState({
        showCheck: true
      })
    }
  }
  drupalFix() {
    const html = document.getElementsByTagName("html")[0]
    //修复某些情况下白屏
    const tigger = setInterval(() => {
      html.scrollIntoView()
    }, 100)
    setTimeout(() => {
      clearInterval(tigger)
      setInterval(() => {
        html.scrollIntoView()
      }, 1000)
    }, 2500);

    html.style.position = "fixed"
    html.style.top = "0px"
    html.style.left = "0px"

    // position: fixed;
    // top: 0;
    // left: 0;


  }
}
ReactWarn2Warn()


export default Page
