
import { RBackground } from '@/component/RBackground';
import { RButton } from '@/component/RBackground/button';
import { RSup } from '@/component/RBackground/sup';
import { RText } from '@/component/RBackground/text';



export const Head = (props: { onCheckClicked: () => void }) => {
    return <>
        <RBackground.PC anchorId="head" url={"/background/head-fix.png"}  drawBoardWidth={5967}>
            <RButton pos={[2048, 3492]} size={[1335, 350]} onClick={props.onCheckClicked}
                background={"/background/icon/check1.png"}
                hover={{
                    url: "/background/icon/check1-hover.png",
                    pos: [0, 0],
                    size: [1335, 350],
                }}
            />
            <RText color={'#FC041E'} pos={[905, 3388]} width={1493} center fontSize={67} space={4}>
                {"「三高」─ 高血壓、高膽固醇、"}
                <>{"高血糖（糖尿病）是慢性腎病的風險因素"}<RSup value={1} />{"。"}</>
            </RText>
            <RText color={"#E78315"} pos={[1225, 3412]} width={1424} center fontSize={67} space={9}>
                <>{"因為慢性腎病在早期通常沒有任何徵狀"}<RSup value={1} />{"，所以不少「三高」患者忽視其嚴重性。"}</>
            </RText>
            <RText pos={[1706, 3484]} width={1335} center fontSize={69} space={3}>
                {"立即查看以下更多有關資訊,"}
                {"並登記進行免費腎病篩查啦！"}
            </RText>
        </RBackground.PC>
        <RBackground.Mobile anchorId="head" url={"/background/mobile/head-fix.png"} drawBoardWidth={1726}>
            <RButton pos={[2359, 439]} size={[919, 261]} radius={"max"} onClick={props.onCheckClicked} />
            <RText color={'#FC041E'} pos={[1557, 141]} width={1493} center fontSize={54} space={4}>
                {"「三高」─ 高血壓、高膽固醇、高血糖（糖尿病）"}
                <>{"是慢性腎病的風險因素"}<RSup value={1} />{"。"}</>
            </RText>
            <RText color={"#E78315"} pos={[1757, 350]} width={1127} center fontSize={53} space={7}>
                <>{"因為慢性腎病在早期通常沒有任何徵狀"}<RSup value={1} />{"，所以不少「三高」患者忽視其嚴重性。"}</>
            </RText>
        </RBackground.Mobile>
    </>
}
