
import { Player } from '@/component/player';
import { RBackground } from '@/component/RBackground';
import { RText } from '@/component/RBackground/text';
import './index.less';



export const Video = (props: { tag: string }) => {
    return <>
        <RBackground.PC anchorId={props.tag}
            drawBoardWidth={5968}
            url={"/background/video-fix.png"} >
            <RText color={"#3A3A3A"} pos={[678, 1194]} width={1841} fontSize={67} space={3.3} line={109}>
                {"聆聽「腎」訴（ #ListentoYourKidney ）是一項由康心摯友與香港復康會社區復康網絡及腎友聯一起推出的腎病關注計劃，推動大家一起正視腎臟發出的警告，主動了解個人罹患慢性腎病的風險及定期接受篩查的重要性。"}
                {" "}
                {"計劃將會在香港推出一項免費篩查計劃，與香港多區的診所及場地合作，推出免費腎病尿液檢測名額，邀請大眾登記進行腎病檢測，並鼓勵大眾 ─ 尤其是「三高」人士主動向家庭醫生了解個人風險。只有及早檢查，才能預防末期腎衰竭或因患上慢性腎病而影響日後的生活質素。"}
            </RText>
            
            <div style={{
                position: "absolute",
                width: 2733,
                height: 1537,
                // height: 1422,//原始设计
                left: 2278,
                top: 1836,
                // top: 1905,//原始设计
                background: "black"
            }}>
                <div style={{
                    width: "100%", height: "100%", display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "wheat",
                    fontSize: 200
                }}>
                    {/* {"Coming Soon"} */}
                    <Player scale={4} />
                </div>
            </div>
        </RBackground.PC >
        <RBackground.Mobile anchorId={props.tag}
            drawBoardWidth={1742}
            url={"/background/mobile/video-fix.png"}>

            <RText color={"#3A3A3A"} pos={[389, 148]} width={1485} fontSize={60} line={86}>
                {"聆聽「腎」訴（ #ListentoYourKidney ）是一項由康心摯友與香港復康會社區復康網絡及腎友聯一起推出的腎病關注計劃，推動大家一起正視腎臟發出的警告，主動了解個人罹患慢性腎病的風險及定期接受篩查的重要性。"}
            </RText>
            <RText color={"#3A3A3A"} pos={[771, 148]} width={1463} fontSize={60} line={109}>
                {"計劃將會在香港推出一項免費篩查計劃，與香港多區的"}
            </RText>
            <RText color={"#3A3A3A"} pos={[863, 148]} width={981} fontSize={60} line={93}>
                {"診所及場地合作，推出免費腎病尿液檢測名額，邀請大眾登記進行腎病檢測，並鼓勵大眾 ─ 尤其是「三高」人士主動向家庭醫生了解個人風險。只有及早檢查，才能預防末期腎衰竭或因患上慢性腎病而影響日後的生活質素。"}
            </RText>

            <div style={{
                position: "absolute",
                width: 1377,
                height: 780,
                // height: 715,//原始设计
                left: 182,
                top: 1564,
                // top: 1601,//原始设计
                background: "black"
            }}>
                <div style={{
                    width: "100%", height: "100%", display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "wheat",
                    fontSize: 128
                }}>
                    {/* {"Coming Soon"} */}
                    <Player scale={5} />
                </div>
            </div>
        </RBackground.Mobile>
    </>
}
