
export const baseUrl = new URL("http://192.168.100.230:3005")


export const autoResBaseUrlString = () => {
    let target = "undef"
    switch (document.location.hostname) {
        case "azhealthclub.com.hk":
            target = `https://azhealthclub.com.hk/modules/custom/azhealthclub_modify/templates/az_foxiga_CKD/dist`
            break;
        case "az.yubis.net":
            target = `https://az.yubis.net/modules/custom/azhealthclub_modify/templates/az_foxiga_CKD/dist`
            break;

        default:
            target = `https://foxiga.ckd.test.yubis.net`
            break;

    }
    //@ts-ignore
    globalThis._debug_autoResBaseUrlString
    return target
}
export const autoAPIBaseUrlString = () => {
    let target = "undef"
    switch (document.location.hostname) {
        case "azhealthclub.com.hk":
            target = `https://azhealthclub.com.hk/api/ckd`
            break;
        case "az.yubis.net":
            target = `https://foxiga.ckd.test.server.yubis.net`
            break;
        default:
            target = `http://127.0.0.1:18092`
            break;
    }
    //@ts-ignore
    globalThis._debug_autoAPIBaseUrlString
    return target
}
