
import { RBackground } from '@/component/RBackground';
import { RButton } from '@/component/RBackground/button';
import { AnchorIds } from '@/pages';
import './index.less';


export const Menu = (props: {
    scrollToSection: (tag: AnchorIds) => void
    onCheckClicked: () => void
}) => {
    return <>
        <RBackground.PC anchorId="menu" url={"/background/menu.png"}  drawBoardWidth={5967}>
            <RButton tag={"bp-check"} pos={[488, 944]} size={[963, 945]} radius={311} onClick={() => { props.scrollToSection("quiz") }} />
            <RButton tag={"bp-video"} pos={[136, 1882]} size={[896, 834]} radius={413} onClick={() => { props.scrollToSection("video") }} />
            <RButton tag={"bp-quiz"} pos={[542, 2672]} size={[877, 834]} radius={413} onClick={() => { props.scrollToSection("section1") }} />
            <RButton tag={"bp-section1"} pos={[126, 3446]} size={[1007, 717]} radius={450} onClick={() => { props.scrollToSection("section2") }} />
            <RButton tag={"bp-section1"} pos={[357, 3563]} size={[519, 717]} radius={450} onClick={() => { props.scrollToSection("section2") }} />
            <RButton tag={"bp-section2"} pos={[711, 4046]} size={[1030, 761]} radius={396} onClick={() => { props.scrollToSection("section3") }} />


        </RBackground.PC>
        <RBackground.Mobile anchorId="menu" url={"/background/mobile/menu.png"}  drawBoardWidth={1730}>
            {/* <RButton tag={"bm-check"} pos={[359, 44]} size={[609, 545]} radius={311} onClick={props.onCheckClicked} /> */}
            <RButton tag={"bm-check"} pos={[359, 44]} size={[609, 545]} radius={311} onClick={() => { props.scrollToSection("quiz") }} />
            <RButton tag={"bm-video"} pos={[27, 515]} size={[581, 452]} radius={413} onClick={() => { props.scrollToSection("video") }} />
            <RButton tag={"bm-quiz"} pos={[458, 634]} size={[489, 425]} radius={413} onClick={() => { props.scrollToSection("section1") }} />
            <RButton tag={"bm-section1"} pos={[74, 1118]} size={[489, 425]} radius={450} onClick={() => { props.scrollToSection("section2") }} />
            <RButton tag={"bm-section2"} pos={[489, 1117]} size={[590, 450]} radius={396} onClick={() => { props.scrollToSection("section3") }} />
        </RBackground.Mobile>

    </>
}
