import { autoResBaseUrlString } from '@/config/config';
import { Button, message } from 'antd';



/**文件网络请求 */
export const ResFetchRaw = async (inputURL: URL) => {
    const response = await fetch(inputURL)
    const arrayBuffer = await response.arrayBuffer()

    const contentType = response.headers.get("content-type") || "application/octet‑stream"
    const contentDate = response.headers.get("date") || new Date(0)
    const contentExpires = response.headers.get("expires") || new Date(0)

    const contentSize = arrayBuffer.byteLength

    const url = new URL(response.url)
    return { url, arrayBuffer, contentType, contentDate, contentExpires, contentSize }
}

/**文件网络请求，包含blob地址 */
export const ResFetch2URL = async (url: URL) => {
    const response = await ResFetchRaw(url)
    let blob = new Blob([response.arrayBuffer]);
    let blobUrl = new URL(URL.createObjectURL(blob))
    return {
        ...response,
        blobUrl,
    }
};

/**图片文件(网络)请求，包含图片大小信息 */
export const getImageInfo = (url: URL): Promise<{
    height: number
    width: number
}> => {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.onload = function () {
            resolve({
                height: img.height,
                width: img.width,
            })
        };
        img.onerror = (event) => {
            reject(event)
        }
        setTimeout(() => {
            reject("timeout")
        }, 15000);
        img.src = url.href
    })
};

/** 拼接资源接口地址
 * 
 * 如果传入的 resPathname 是一个有效的URLString ，则直接返回URLString生成的URL
 */
export const resURLConcatenation = async (resPathname: Readonly<string>, withVersionSearch?: boolean | string): Promise<URL> => {
    try { return new URL(resPathname) } catch (error) { }

    const baseURLString = autoResBaseUrlString()
    let nextURLPrefix = ""

    const lastUrlStringText = baseURLString.substring(baseURLString.length - 1)
    if (lastUrlStringText === "/") {
        nextURLPrefix = baseURLString.substring(0, baseURLString.length - 1)
    } else {
        nextURLPrefix = baseURLString
    }


    const finalURL = new URL(nextURLPrefix + resPathname)
    if (typeof withVersionSearch === "string") {
        finalURL.searchParams.append("ver", withVersionSearch)
    } else if (withVersionSearch) {
        const verText = await getBuildVerText()
        finalURL.searchParams.append("ver", verText)
    }

    return finalURL
};
export const getBuildVerText = (): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        //@ts-ignore
        if (globalThis.buildVerText) {
            //@ts-ignore
            resolve(globalThis.buildVerText)
        }

        if ("fetchingBuildVerText" in window && Array.isArray(fetchingBuildVerText)) {
            fetchingBuildVerText.push(resolve)
            return
        }
        //@ts-ignore
        globalThis.fetchingBuildVerText = []


        const url = await resURLConcatenation("/ver.txt", String(new Date().valueOf()))
        try {
            const response = await fetch(url)
            const data = await response.text()
            //@ts-ignore
            globalThis.buildVerText = data
        } catch (error) {
            console.error("getBuildVerText,networkFailed", url.href)
            //@ts-ignore
            globalThis.buildVerText = "error"

        }
        //@ts-ignore
        resolve(globalThis.buildVerText)
        if ("fetchingBuildVerText" in window && Array.isArray(fetchingBuildVerText)) {
            while (fetchingBuildVerText.length > 0) {
                const callback = fetchingBuildVerText.pop()
                //@ts-ignore
                callback(globalThis.buildVerText)
            }
            //@ts-ignore
            globalThis.fetchingBuildVerText = undefined
        }

    })

};
