import { ResFetch2URL, getImageInfo } from "@/function/Network";
import React, { PureComponent } from "react";
import "./index.less"

type RTextConfig = {
    debug?: boolean
}
export const RTextConfig = React.createContext<RTextConfig>({});

type RTextProps = {
    children: React.ReactNode | React.ReactNode[] | string | string[]
    pos: [top: number, left: number,]
    width: number |string
    center?: boolean | "right"
    fontSize: number
    space?: number
    // bold?: boolean'
    /**    line-height */
    line?: number
    weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
    // show?: boolean
    color?: string
    indent?: number
    bottom?: number

    _oneLine?: boolean
}
type RTextState = {
}
export class RText extends PureComponent<RTextProps, RTextState> {
    timer?: NodeJS.Timer;
    constructor(props: RTextProps) {
        super(props);
        this.state = {
        };
    }
    render() {
        const className = ["RText"]

        return <RTextConfig.Consumer>
            {CONFIG => {
                return <div className={className.join(" ")} style={{
                    position: "absolute",
                    top: this.props.pos[0],
                    left: this.props.pos[1],
                    fontSize: this.props.fontSize,
                    fontWeight: this.props.weight,
                    width: this.props.width,
                    letterSpacing: this.props.space,
                    lineHeight: this.props.line ? `${this.props.line}px` : undefined,
                    textAlign: this.props.center ? (this.props.center === "right" ? "right" : "center") : "left",
                    color: this.props.color || (CONFIG.debug ? "red" : "transparent"),
                    backgroundColor: (CONFIG.debug && this.props.color) ? "rgb(82 196 26 / 25%)" : undefined,
                    border: CONFIG.debug ? "1px red solid" : undefined,
                    textIndent: this.props.indent,
                }} >
                    {this.renderLine()}
                </div>
            }}
        </RTextConfig.Consumer>
    }
    
    renderLine() {
        const children = this.props.children
        if (typeof children === "string") {
            return children
        } else if (Array.isArray(children)) {
            return Array.from(children, (line) => {
                return <div style={{ minHeight: this.props.line ? `${this.props.line}px` : undefined, marginBottom: this.props.bottom }}>
                    {line}
                </div>
            })
        } else {
           return children
        }

    }
    componentDidMount() {
    }
}
