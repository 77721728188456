
import { PureComponent } from 'react';




type HKCodeProps = {
    tag: string
}
type State = {
}
export class HKCode extends PureComponent<HKCodeProps, State> {
    constructor(props: HKCodeProps) {
        super(props)
        this.state = {
            selected: [],
        }
    }

    render() {

        return <div className='HKCode' style={{
            display: "flex",
            justifyContent: "center"
        }}
        >
            <div style={{
                maxWidth: 1100,
                width:"100%",
                padding: 24,
                textAlign: "left"
            }}>
                {this.props.tag}
            </div>
        </div>
    }

}
