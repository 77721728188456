import { BackTop as ANTD_BackTop } from "antd"






export const BackTop = () => {
    return <ANTD_BackTop>
        <div style={{
            position: "relative",
            left: -30,
            top: -40,
            backgroundImage: `url(${"/backtop.png"})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: 75,
            width: 67,

        }} />
    </ANTD_BackTop>
}