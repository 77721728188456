import { ResFetch2URL, getImageInfo } from "@/function/Network";
import React, { PureComponent } from "react";

type RSupConfig = {
    debug?: boolean
}
export const RSupConfig = React.createContext<RSupConfig>({});

type RSupProps = {
    children: string | string[]
} | {
    value: number
}
type RSupState = {
}
export class RSup extends PureComponent<RSupProps, RSupState> {
    timer?: NodeJS.Timer;
    constructor(props: RSupProps) {
        super(props);
        this.state = {
        };
    }
    render() {
        const className = ["RSup"]

        return <RSupConfig.Consumer>
            {CONFIG => {
                return <div className={className.join(" ")} style={{
                    transform: `scale(0.5)`,
                    display: "inline-block",
                    transformOrigin: "left top",
                    position: "relative",
                    top: `0.1em`,
                    textIndent: 0,
                }} >
                    {"value" in this.props ? this.props.value : this.props.children}
                </div>
            }}
        </RSupConfig.Consumer>
    }
    componentDidMount() {
    }
}
