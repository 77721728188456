import { ResFetch2URL, getImageInfo } from "@/function/Network";
import React, { PureComponent } from "react";
import { Button, Checkbox, Typography, Form, Input, Modal, Select, Space } from "antd";
import { RBackground } from "@/component/RBackground";
import { RText } from "@/component/RBackground/text";
import debug from "@umijs/deps/compiled/debug";
import { FormProps } from "antd/es/form/Form";
const { Option } = Select;
const { Title } = Typography;




type RButtonConfig = {
    debug?: boolean
}

export const RButtonConfig = React.createContext<RButtonConfig>({});

type CheckProps = {
    show: boolean
    onCheckClicked: () => void
    onClose: () => void
}


type CheckState = {
    // open: boolean
}
export class Quiz extends PureComponent<CheckProps, CheckState> {
    timer?: NodeJS.Timer;
    constructor(props: CheckProps) {
        super(props);
        this.state = {
            // open: this.props.
        };
    }

    render() {
        return <>
            <Modal
                width={"80%"}
                style={{
                    maxWidth: 550,
                    borderRadius: 25,
                    overflow: "hidden",
                    top:180,
                }}
                closable
                // visible={this.state.open}
                // open={this.state.open}
                open={this.props.show}
                title={null}
                footer={null}
                // onOk={handleOk}
                onCancel={this.props.onClose}
            >
                <div style={{ minHeight: 200, background: "#20709D", cursor: "pointer" }} onClick={this.props.onCheckClicked}>
                    <RBackground anchorId={"QuizModal"}
                        url={"/background/quizModal.png"} >
                    </RBackground>
                </div>
            </Modal>
            <img src={"/background/quizModal.png"} style={{ display: "none" }} />
        </>
    }
    componentDidMount() {
    }
    onFormFieldsChange = (event: Parameters<Extract<FormProps["onFieldsChange"], Function>>[0]) => {
        const first = event.shift()
        if (first) {

            if (typeof first.name === "string") {
                const names = new Set(first.name)
                // if(names.has(""))
            }
            console.log("first",)
        }
    }

}
