





export const Player = (props: { video?: URL, scale?: number }) => {
    const scale = props.scale || 1
    return <>
        <video
            src="https://d2nj8mxip63ii7.cloudfront.net/videos/azhc_forxiga_ckd_video3_20221026.mp4"
            controls={true}
            style={{
                width: `${100 / scale}%`,
                height: `${100 / scale}%`,
                transform: `scale(${scale})`,
                // transformOrigin: `left top`,
                // zoom: 5
            }}></video>

    </>
}
