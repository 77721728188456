
import { RBackground } from '@/component/RBackground';
import { RButton } from '@/component/RBackground/button';
import { setAsset } from '@/DynamicAssets';
import { DeviceDetector } from '@/function/DeviceDetector';
import { Checkbox } from 'antd';
import React, { PureComponent } from 'react';
import './index.less';



const Device = new DeviceDetector()
export const DeviceInfoContext = React.createContext(Device.info);

type SectionQuizProps = {
    tag: string
    onQuizClicked: () => void
}
type SectionQuizState = {
}

export class SectionQuiz extends PureComponent<SectionQuizProps, SectionQuizState> {
    constructor(config: SectionQuizProps) {
        super(config)
        this.state = {
        }
    }

    render() {
        return <>
            <RBackground.PC anchorId={this.props.tag}
                url={"/background/quiz-fix.png"} drawBoardWidth={1920}>
                <SectionForm pos={[281, 520]} size={[879, 553]} submit={this.props.onQuizClicked} />
            </RBackground.PC>
            <RBackground.Mobile anchorId={this.props.tag}
                url={"/background/mobile/quiz-fix.png"} drawBoardWidth={800}>
                <SectionForm pos={[226, 0]} size={[879, 553]}
                    submit={this.props.onQuizClicked} mobileLayout />
            </RBackground.Mobile>
        </>
    }
    componentDidMount() {
        setAsset("fonts")

        Device.onChange = (info) => {
            this.setState({
                device: info
            })
        }

    }
    getDeviceInfo() {
        return new DeviceDetector()
    }
}

type SectionFormProps = {
    mobileLayout?: boolean
    submit: () => void
    pos: [top: number, left: number,]
    size: [width: number, height: number]
}
type SectionFormState = {
    selected: {
        sectionId: number,
        checkId: 1 | 2
    }[]
}
class SectionForm extends PureComponent<SectionFormProps, SectionFormState> {
    constructor(props: SectionFormProps) {
        super(props)
        this.state = {
            selected: [],
        }
    }

    render() {
        if (this.props.mobileLayout) {
            return <div className='SectionForm mobileLayout' style={{
                position: "absolute",
                top: this.props.pos[0],
                left: this.props.pos[1],
                width: this.props.size[0],
                height: this.props.size[1],
                backgroundColor: this.randomColor(),
                transition: `background-color 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s`
            }}
            >
                {Array.from(new Array(8), (item, sectionId) => <SectionFormSelection mobileLayout
                    okText={sectionId === 4 || sectionId === 5 ? "是" : undefined}
                />)}

                <RButton pos={[478, 324]} size={[290, 94]} radius={"max"} onClick={this.props.submit}
                    background={"/background/icon/check2.png"} />
            </div >
        }
        return <div className='SectionForm' style={{
            position: "absolute",
            top: this.props.pos[0],
            left: this.props.pos[1],
            width: this.props.size[0],
            height: this.props.size[1],
            backgroundColor: this.randomColor(),
            transition: `background-color 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s`
        }}
        >
            {Array.from(new Array(8), (item, sectionId) => <SectionFormSelection
                okText={sectionId === 4 || sectionId === 5 ? "是" : undefined}
            />)}
            <RButton pos={[579, 311]} size={[300, 114]} onClick={this.props.submit}
                background={"/background/icon/check2.png"}
                hover={{
                    url: "/background/icon/check2-hover.png",
                    pos: [0, 0],
                    size: [300, 114],
                }}
            />
            {/* <RButton pos={[579, 311]} size={[300, 114]} radius={"max"} onClick={this.props.submit} /> */}
        </div>
    }
    componentDidMount() {


    }
    randomColor() {
        let r = Math.floor(Math.random() * 255);
        let g = Math.floor(Math.random() * 255);
        let b = Math.floor(Math.random() * 255);
        let color = 'rgba(' + r + ',' + g + ',' + b + ',0.2)';
        return "color"
    }
}

type SectionFormSelectionProps = {
    mobileLayout?: boolean
    okText?: string

}
type SectionFormSelectionState = {
    left?: boolean

}
class SectionFormSelection extends PureComponent<SectionFormSelectionProps, SectionFormSelectionState> {
    constructor(props: SectionFormSelectionProps) {
        super(props)
        this.state = {
        }
    }

    render() {
        if (this.props.mobileLayout) {
            return <div className='SectionForm-Row  mobileLayout'
                style={{ height: 56 }}
            >
                <div className='SectionForm-Cal title mobileLayout' style={{ width: 419 }}> </div>
                <div className='SectionForm-Cal selection mobileLayout selection-key-1' style={{ width: 126 }}>
                    <Checkbox style={{ transform: `scale(${2})` }}
                        checked={this.state.left === true}
                        onClick={() => { this.setState({ left: true }) }} >{this.props.okText || "有"}</Checkbox>
                </div>
                <div className='SectionForm-Cal selection mobileLayout selection-key-2'  >
                    <Checkbox style={{ transform: `scale(${2})` }}
                        checked={this.state.left === false}
                        onClick={() => { this.setState({ left: false }) }}>{"否"}</Checkbox>
                </div>
            </div>
        }
        return <div className='SectionForm-Row'>
            <div className='SectionForm-Cal title'> </div>
            <div className='SectionForm-Cal selection selection-key-1'>
                <Checkbox style={{ transform: `scale(${2})` }}
                    checked={this.state.left === true}
                    onClick={() => { this.setState({ left: true }) }} >{this.props.okText || "有"}</Checkbox>
            </div>
            <div className='SectionForm-Cal selection selection-key-2'  >
                <Checkbox style={{ transform: `scale(${2})` }}
                    checked={this.state.left === false}
                    onClick={() => { this.setState({ left: false }) }}>{"否"}</Checkbox>
            </div>
        </div>
    }

}

