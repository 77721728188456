
import { Typography } from 'antd';
import { PureComponent } from 'react';

const { Title } = Typography;



type SectionReferenceProps = {
    tag: string
}
type State = {
}
export class SectionReference extends PureComponent<SectionReferenceProps, State> {
    constructor(props: SectionReferenceProps) {
        super(props)
        this.state = {
            selected: [],
        }
    }

    render() {

        return <div className='SectionReference' style={{
            display: "flex",
            justifyContent: "center"
        }}
        >
            <div style={{
                maxWidth: 1100,
                padding: 24,
                textAlign: "left"
            }}>
                <table style={{ border: 0 }}>
                    <thead style={{ border: 0 }}>
                        <tr style={{ border: 0 }}>
                            <th colSpan={2} style={{ border: 0 }}>
                                <Title level={5}>
                                    {"參考資料："}
                                </Title>
                            </th>
                        </tr>
                    </thead>
                    <tbody style={{ border: 0 }}>
                        {Array.from(REF_TEXT.split("\n"), (line) => {
                            if (!line) { return "" }
                            const splited = line.split(".")
                            const sn = splited.shift()
                            const content = splited.join(".")
                            return <tr style={{ border: 0 }}>
                                <td style={{ verticalAlign: "top", paddingRight: 5, textAlign: "right", border: 0 }}>{sn}.</td>
                                <td style={{ wordBreak: "break-all", border: 0 }}>{content}</td>
                            </tr>

                        })}
                    </tbody>
                </table>
            </div>
        </div>
    }

}


const REF_TEXT = `
1.National Health Services. Chronic kidney disease - overview. 29 August 2019. Available at: https://www.nhs.uk/conditions/kidney-disease/. Accessed: 8 August 2022. 
2.Centers for Disease Control and Prevention. Chronic kidney disease basics. 28 February 2022. Available at:https://www.cdc.gov/kidneydisease/basics.html. Accessed: 8 August 2022.  
3.Centre for Health Protection, Department of Health, Hong Kong. Be Aware of Chronic Kidney Disease. February 2020. Available at: https://www.chp.gov.hk/files/pdf/ncd_watch_february_2020.pdf. Accessed: 8 August 2022.  
4.Smart Patient. Hospital Authority. Chronic renal failure. Available at: https://www21.ha.org.hk/smartpatient/SPW/en-us/Disease-Information/Disease/?guid=368b30e4-cc1c-4185-b673-7dfb3ea8f74b. Accessed: 8 August 2022.  
5.Centers for Disease Control and Prevention. CKD related health problems. 4 March 2021. Available at: https://www.cdc.gov/kidneydisease/publications-resources/annual-report/ckd-related-health-problems.html. 8 August 2022. 
6.Li PKT, et al. Kidney health for everyone everywhere - from prevention to detection and equitable access to care.Hong Kong Med J. 2020;26:8.e1-9. Available at: https:// doi.org/10.12809/hkmj198292. Accessed: 8 August 2022.  
7.sohealthy.com.hk. ARPMHA Report. 29 November 2021. Available at: https://www.sohealthy.com.hk/medical/29/2021/1129renalscreening/. Accessed: 8 August 2022. 
8.Centre for Health Protection, Department of Health, Hong Kong. Death rates by leading causes of death, 2001-2020. Available at: https://www.chp.gov.hk/en/statistics/data/10/27/117.html. Accessed: 8 August 2022.  
9.National Kidney Foundation. High blood pressure and chronic kidney disease. Available at: https://www.kidney.org/news/newsroom/facsheets/High-Blood-Pressure-and-CKD. Accessed: 8 August 2022. 
10.Centers for Disease Control and Prevention. 7 May 2021. Diabetes and chronic kidney disease. Available at: https://www.cdc.gov/diabetes/managing/diabetes-kidney-disease.html. Accessed: 8 August 2022. 
11.National Kidney Foundation. High cholesterol = high kidney disease risk. 12 August 2014. Available at: https://www.kidney.org/news/kidneyCare/Summer10/HighCholesterol.Accessed: 8 August 2022. 
12.Centers for Disease Control and Prevention. CKD risk factors and prevention. 12 July 2022. Available at: https://www.cdc.gov/kidneydisease/publications-resources/annual-report/ckd-risk-prevention.html. Accessed: 8 August 2022 
13.worldkidneyday.org. What is hypertension? Available at: https://www.worldkidney day.org/facts/topics/hypertension/. Accessed: 8 August 2022. 
14.Ling W, et al. Global trend of diabetes mortality attributed to vascular complications, 2000-2016. Cardiovasc Diabe tol. 2020;19(1):182. Available at: https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7573870/pdf/12933_2020_Article_1159.pdf. Accessed: 8 August 2022. 
15.Jankowski J, et al. Cardiovascular disease in chronic kidney disease pathophysiological insights and therapeutic options. Circulation. 2021;143(11):1157–1172. 
16.worldkidneyday.org. Obesity & Kidney Disease. Available at: https://www.worldkidneyday.org/facts/topics/obesity-kidney-disease/. Accessed: 8 August 2022. 
17.National Health Services. Chronic kidney disease - symptoms. 29 August 2019. Available at: https://www.nhs.uk/conditions/kidney-disease/symptoms/. Accessed: 8 August 2022. 
18.Saunders MR, et al. JAMA guideline synopsis: chronic kidney disease screening. JAMA. 2015;314(6):615-616. Available at: https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4813784/. Accessed: 8 August 2022.  
19.National Kidney Foundation. Quick reference guide on kidney disease screening.Available at: https://www.kidney.org/kidneydisease/siemens_hcp_quickreference.Accessed: 8 August 2022. 
20.National Kidney Foundation. Estimated glomerular filtration rate (eGFR). Available at: https://www.kidney.org/atoz/content/gfr. Accessed: 8 August 2022. 
21.National Health Service. Chronic kidney disease - diagnosis. 29 August 2019. Available at: https://www.nhs.uk/conditions/kidney-disease/diagnosis/. Accessed: 8 August2022. 
22.National Health Service. Chronic kidney disease – treatment. 29 August 2019. Available at: https://www.nhs.uk/conditions/kidney-disease/treatment/. Accessed: 8 August2022. 
23.Care Online. Truth about chronic kidney disease. 30 March 2018. Available at: https://www.careonline.com.tw/2018/03/renal-disease.html. Accessed: 8 August 2022. 
24. Centre for Health Protection, Department of Health, Hong Kong. Body Mass IndexChart. Available at: https://www.chp.gov.hk/tc/resources/e_health_topics/pdfwav_11012.html. Accessed: 8 August 2022. 
25. ppfocus.com. What does plus protein in urine mean? 10 December 2020. Available at: https://ppfocus.com/0/ed5739d3a.html. Accessed: 8 August 2022.
26. helloyishi.com.tw. Understanding creatinine and kidney disease. Available at: https://helloyishi.com.tw/urological-health/kidney-disease/creatinine-and-kidney-disease/. Accessed: 8 August 2022.

`