
import { RBackground } from '@/component/RBackground';
import { RButton } from '@/component/RBackground/button';
import { RSup } from '@/component/RBackground/sup';
import { RText } from '@/component/RBackground/text';
import { DeviceDetector } from '@/function/DeviceDetector';
import React from 'react';
import './index.less';



const Device = new DeviceDetector()
export const DeviceInfoContext = React.createContext(Device.info);


export const Section1 = (props: { tag: string }) => {
    return <>
        <RBackground.PC anchorId={props.tag}
            drawBoardWidth={5963}
            url={"/background/section1-fix2.png"}>
            <RText color={"white"} pos={[3311, 1212]} width={513} fontSize={69} line={93} center={true}>{"調節體內液流量"}</RText>
            <RText color={"white"} pos={[3311, 1875]} width={382} fontSize={69} line={93} center={true}>{"過濾血液中的雜質"}</RText>
            <RText color={"white"} pos={[3311, 2395]} width={513} fontSize={69} line={93} center={true}>{"保持血液中礦物質的平衝"}</RText>
            <RText color={"white"} pos={[3311, 2985]} width={513} fontSize={69} line={93} center={true}>{"刺激紅血球生成"}</RText>
            <RText color={"white"} pos={[3311, 3604]} width={513} fontSize={69} line={93} center={true}>{"控制血壓"}</RText>
            <RText color={"white"} pos={[3311, 4179]} width={513} fontSize={69} line={93} center={true}>{"保持骨骼健康"}</RText>
        </RBackground.PC>
        <RBackground.Mobile anchorId={props.tag}
            drawBoardWidth={1730}
            url={"/background/mobile/section1-fix.png"} >
            <RText color={"white"} pos={[2717, 111]} width={511} fontSize={58} line={93} center={true}>{"調節體內液流量"}</RText>
            <RText color={"white"} pos={[2717, 690]} width={345} fontSize={58} line={93} center={true}>{"過濾血液中的雜質"}</RText>
            <RText color={"white"} pos={[2717, 1157]} width={380} fontSize={58} line={93} center={true}>{"保持血液中礦物質的平衝"}</RText>
            <RText color={"white"} pos={[3226, 119]} width={513} fontSize={58} line={93} center={true}>{"刺激紅血球生成"}</RText>
            <RText color={"white"} pos={[3226, 609]} width={513} fontSize={58} line={93} center={true}>{"控制血壓"}</RText>
            <RText color={"white"} pos={[3226, 1096]} width={513} fontSize={58} line={93} center={true}>{"保持骨骼健康"}</RText>
        </RBackground.Mobile>
    </>
}






export const Section2 = (props: { tag: string }) => {
    return <>
        <RBackground.PC anchorId={props.tag}url={"/background/section2-fix.png"}  drawBoardWidth={5968} >
            <RText color={"black"} pos={[998, 1633]} width={2970} fontSize={66} line={103} space={6} bottom={121}>
                <>
                    <span style={{ color: "red", fontWeight: 600 }}>{"高血壓"}</span><span style={{ fontWeight: 600 }}>{"："}</span>
                    {"血壓過高會導致腎臟的血管收縮和變窄，從而減少血液流量，令腎臟不能正常運作，無法排出所有身體的廢物和多餘液體，進而造成腎功能受損甚至衰竭"}<RSup value={9} />{"。"}
                </>
                <>
                    <span style={{ color: "red", fontWeight: 600 }}>{"高血糖（糖尿病）"}</span><span style={{ fontWeight: 600 }}>{"："}</span>
                    <>{"體內過高的血糖會不斷傷害腎臟的血管，令腎功能受損"}<RSup value={10} />{"。"}</>
                    <><div style={{ minHeight: 100 }}>{" "}</div></>
                </>

                <>
                    <span style={{ color: "red", fontWeight: 600 }}>{"高膽固醇"}</span><span style={{ fontWeight: 600 }}>{"："}</span>
                    {"過多的膽固醇會在血管中積聚，令血管變窄及阻塞，從而阻止血液進入身體的某個區域，導致冠心病、慢性腎病等"}<RSup value={11} />{"。"}
                </>
                <>
                    <span style={{ color: "red", fontWeight: 600 }}>{"其他因素"}</span><span style={{ fontWeight: 600 }}>{"："}</span>
                    {"心臟病、肥胖、慢性腎病家族史、遺傳性腎臟疾病、腎臟損傷史、年齡較大等"}<RSup value={12} />{"。"}
                </>
            </RText>


            <RText color={"black"} pos={[2874, 1473]} width={764} fontSize={66} line={103} space={6} center>
                <>{"每10名患有第3-5期的慢性腎病患者，當中9名同時患有高血壓"}<RSup value={13} /></>
            </RText>

            <RText color={"black"} pos={[2874, 2336]} width={568} fontSize={66} line={103} space={6} center>
                <>{"約40%患者會併發慢性腎病"}<RSup value={14} /></>
            </RText>

            <RText color={"black"} pos={[2874, 3180]} width={491} fontSize={66} line={103} space={6} center>
                <>{"約50%第4-5期慢性腎病同時患有心血管疾病"}<RSup value={15} /></>
            </RText>

            <RText color={"black"} pos={[2874, 3920]} width={580} fontSize={66} line={103} space={6} center>
                <>{"過重或癡肥人士患上末期腎病的機率會高2-7倍"}<RSup value={16} /></>
            </RText>


            <RText color={"black"} pos={[3918, 1849]} width={2313} fontSize={66} line={103} space={6} center>
                <>{"早期慢性腎病通常沒有症狀；當出現症狀時，病情可能已經發展至晚期慢性腎病"}<RSup value={17} />{"。"}</>
            </RText>

            <RText color={"black"} pos={[4572, 2163]} width={828} fontSize={66} line={103} space={0} >
                <>{"小便帶血/茶色尿(血尿)"}</>
                <>{"小便混濁(尿感染)"}</>
                <>{"小便感困難、不暢順"}</>
                <>{"腰腹疼痛"}</>
            </RText>
            <RText color={"black"} pos={[4572, 3222]} width={876} fontSize={66} line={103} space={0} >
                <>{"小便帶泡沫(蛋白尿)"}</>
                <>{"小便赤痛"}</>
                <>{"多尿、尿量減少、頻密夜尿"}</>
                <>{"足踝或眼皮腫脹"}</>
            </RText>
            <RText color={"#21719d"} pos={[5152, 2085]} width={1856} fontSize={66} line={103} weight={600} space={0} center>
                {"有上述症狀人士切勿忽視慢性腎病的嚴重性，應盡快求醫及早檢查及治療， 防止病情惡化。"}
            </RText>
            <RText color={"black"} pos={[5922, 2163]} width={828} fontSize={66} line={103} space={0} >
                <>{"頻密夜尿、尿量減少"}</>
                <>{"食慾不振、噁心、嘔吐"}</>
                <>{"疲倦、臉色蒼白(貧血)"}</>
                <>{"皮膚痕癢"}</>
            </RText>
            <RText color={"black"} pos={[5922, 3222]} width={876} fontSize={66} line={103} space={0} >
                <>{"高血壓"}</>
                <>{"氣喘"}</>
                <>{"水腫"}</>
                <>{"神智不清、抽搐、昏迷"}</>
            </RText>
            <RText color={"#21719d"} pos={[6503, 2085]} width={1856} fontSize={66} line={103} weight={600} space={0} center>
                {"腎功能衰竭會增加患上心血管疾病風險，所以及早提防慢性腎病極為重要。"}
            </RText>
            <RText color={"black"} pos={[8180, 2271]} width={1798} fontSize={70} line={109} bottom={111} center>
                <>{"目前治療慢性腎病的主要策略是減緩腎臟損傷。其方法是透過藥物控制風險因素，例如控制高血壓、高膽固醇等問題，以保護腎臟"}<RSup value={22} />{"。"}</>
                <>{"當慢性腎病發展至晚期腎衰竭，患者的腎臟只餘下<15%的正常腎功能，此時患者不能有效排出體內的廢物及過多水分，因而需要以透析治療（洗腎）以維持生命"}<RSup value={3} />{"。"}</>
                <div style={{ width: 1165, display: "inline-block" }}>{"有「三高」問題人士應慎防慢性腎病，及早檢測腎臟功能有否受損或喪失。"}</div>
            </RText>
        </RBackground.PC>
        <RBackground.Mobile anchorId={props.tag} url={"/background/mobile/section2-fix.png"} drawBoardWidth={1731} >
            <RText color={"black"} pos={[599, 136]} width={1464} fontSize={66} line={88} space={6} center>
                <span style={{ color: "red", fontWeight: 600 }}>{"高血壓"}</span>
                <>{"血壓過高會導致腎臟的血管收縮和變窄，從而減少血液流量，令腎臟不能正常運作，無法排出所有身體的廢物和多餘液體，進而造成腎功能受損甚至衰竭"}<RSup value={9} />{"。"}</>
                <>{" "}</>
                <span style={{ color: "red", fontWeight: 600 }}>{"高血糖（糖尿病）"}</span>
                <>{"體內過高的血糖會不斷傷害腎臟的血管，令腎功能受損"}<RSup value={10} />{"。"}</>
                <>{" "}</>
                <span style={{ color: "red", fontWeight: 600 }}>{"高膽固醇"}</span>
                <>{"過多的膽固醇會在血管中積聚，令血管變窄及阻塞，從而阻止血液進入身體的某個區域，導致冠心病、慢性腎病等"}<RSup value={11} />{"。"}</>
                <>{" "}</>
                <span style={{ color: "red", fontWeight: 600 }}>{"其他因素"}</span>
                <>{"心臟病、肥胖、慢性腎病家族史、遺傳性腎臟疾病、腎臟損傷史、年齡較大等"}<RSup value={12} />{"。"}</>
            </RText>


            <RText color={"black"} pos={[2742, 227]} width={598} fontSize={49} line={84} space={6} center>
                <>{"每10名患有第3-5期的慢性腎病患者，當中9名同時患有高血壓"}<RSup value={13} /></>
            </RText>
            <RText color={"black"} pos={[2742, 1032]} width={441} fontSize={49} line={84} space={6} center>
                <>{"約40%患者會併發慢性腎病"}<RSup value={14} /></>
            </RText>
            <RText color={"black"} pos={[3538, 227]} width={598} fontSize={49} line={84} space={6} center>
                <>{"約50%第4-5期慢性腎病同時患有心血管疾病"}<RSup value={15} /></>
            </RText>
            <RButton background={"#E5202B"} pos={[3384, 320]} radius={"max"} size={[400, 100]}>
                <RText color={"white"} pos={[0, 0]} width={"100%"} fontSize={52} line={97} space={6} center>
                    {"心血管疾病"}
                </RText>
            </RButton>
            <RText color={"black"} pos={[3538, 993]} width={488} fontSize={49} line={84} space={6} center>
                <>{"過重或癡肥人士患上末期腎病的機率會高2-7倍"}<RSup value={16} /></>
            </RText>


            <RText color={"black"} pos={[4200, 741]} width={874} fontSize={56} line={93} space={3} center>
                <>{"早期慢性腎病通常沒有症狀；當出現症狀時，病情可能已經發展至晚期慢性腎病"}<RSup value={17} />{"。"}</>
            </RText>

            <RText color={"black"} pos={[4900, 331]} width={662} fontSize={53} line={97} space={0} >
                <>{"小便帶血/茶色尿(血尿)"}</>
                <>{"小便混濁(尿感染)"}</>
                <>{"小便感困難、不暢順"}</>
                <>{"腰腹疼痛"}</>
            </RText>
            <RText color={"black"} pos={[4900, 959]} width={662} fontSize={53} line={97} space={0} >
                <>{"小便帶泡沫(蛋白尿)"}</>
                <>{"小便赤痛"}</>
                <>{"多尿、尿量減少、頻密夜尿"}</>
                <>{"足踝或眼皮腫脹"}</>
            </RText>
            <RText color={"#21719d"} pos={[5324, 263]} width={1263} fontSize={48} line={80} space={0} center>
                {"有上述症狀人士切勿忽視慢性腎病的嚴重性，應盡快求醫及早檢查及治療， 防止病情惡化。"}
            </RText>
            <RText color={"black"} pos={[5887, 331]} width={662} fontSize={53} line={97} space={0} >
                <>{"頻密夜尿、尿量減少"}</>
                <>{"食慾不振、噁心、嘔吐"}</>
                <>{"疲倦、臉色蒼白(貧血)"}</>
                <>{"皮膚痕癢"}</>
            </RText>

            <RText color={"black"} pos={[5887, 959]} width={662} fontSize={53} line={97} space={0} >
                <>{"高血壓"}</>
                <>{"氣喘"}</>
                <>{"水腫"}</>
                <>{"神智不清、抽搐、昏迷"}</>
            </RText>
            <RText color={"#21719d"} pos={[6320, 263]} width={1263} fontSize={48} line={80} space={0} center>
                {"腎功能衰竭會增加患上心血管疾病風險，所以及早提防慢性腎病極為重要。"}
            </RText>
            <RText color={"black"} pos={[7096, 179]} width={1408} fontSize={62} line={95} bottom={91} center>
                <>{"目前治療慢性腎病的主要策略是減緩腎臟損傷。其方法是透過藥物控制風險因素，例如控制高血壓、高膽固醇等問題，以保護腎臟"}<RSup value={22} />{"。"}</>
                <>{"當慢性腎病發展至晚期腎衰竭，患者的腎臟只餘下<15%的正常腎功能，此時患者不能有效排出體內的廢物及過多水分，因而需要以透析治療（洗腎）以維持生命"}<RSup value={3} />{"。"}</>
                <div style={{ width: 1165, display: "inline-block" }}>{"有「三高」問題人士應慎防慢性腎病，及早檢測腎臟功能有否受損或喪失。"}</div>
            </RText>
        </RBackground.Mobile>
    </>
}

export const Section3 = (props: { tag: string }) => {
    return <>
        <RBackground.PC anchorId={props.tag} url={"/background/section3-fix.png"} drawBoardWidth={3992}>

            <RText color={"white"} pos={[380, 1357]} width={1354} center fontSize={92} space={5} weight={700}>
                {"怎麼知道有沒有慢性腎病?"}
            </RText>
            <RText color={"white"} pos={[639, 1231]} width={1521} center fontSize={46} space={1} weight={300}>
                <>{"多個國際醫學協會均建議有高血壓及糖尿病等人士定期進行篩查，以盡早發現慢性腎病"}<RSup value={18} />{"。"}</>
            </RText>
            <RText color={"white"} pos={[855, 1023]} width={1963} center fontSize={44} space={1} weight={300}>
                <>{"尿液檢查白蛋白及血液檢查血清肌酸酐水平以估算腎小球濾過率是篩查慢性腎病的有效方法 "}<RSup value={19} />{"。"}</>
            </RText>
            <RText color={"white"} pos={[1123, 1231]} width={1521} center fontSize={46} space={1} weight={300}>
                <>{"慢性腎病雙指標 ：蛋白尿 + 腎小球濾過率"}</>
            </RText>


            <RText color={"white"} pos={[1806, 1091]} width={408} center fontSize={44} space={1} weight={300}>
                <>{"尿液檢驗試紙"}</>
            </RText>
            <RText color={"white"} pos={[1758, 2031]} width={570} center fontSize={42} space={1} weight={300}>
                <>{"驗尿"}</>
                <>{"（尿白蛋白及肌酸酐比值）"}</>
            </RText>
            <RText color={"white"} pos={[1762, 2654]} width={408} center fontSize={44} space={1} weight={300}>
                <>{"驗血"}</>
                <>{"（血清肌酸酐檢查）"}</>
            </RText>


            <RText color={"white"} pos={[2253, 1357]} width={1354} center fontSize={92} space={1} weight={300}>
                {"蛋白尿檢查"}
            </RText>

            <RText color={"white"} pos={[2528, 1114]} width={1901} center fontSize={44} space={2} weight={300}>
                <>{"最簡單快捷的方法是用尿蛋白檢測試紙作檢查，只需將試紙進入尿液中觀察試紙顏色變化再與蛋白質標準色板比較，便可查出有否蛋白尿。"}</>
            </RText>
            <RText color={"#98cf16"} pos={[2954, 2024]} width={859} fontSize={44} space={2} weight={300}>
                <>{"＞1個“+”號，代表腎功能有機會下降；異常尿蛋白“+”號越多，代表尿中白蛋白的濃度比較高"}<RSup value={25} /></>
            </RText>
            <RText color={"white"} pos={[3460, 1305]} width={1482} fontSize={44} space={1} weight={300} center>
                <>{"如果持續發現有蛋白尿，就須進一步檢查血液肌酸酐水平，並確認尿白蛋白量，以檢測白蛋白／肌酸酐比值，全面了解腎功能狀況。"}</>
            </RText>


            <RText color={"white"} pos={[4055, 1136]} width={1827} fontSize={44} space={2} weight={300} center>
                <>{"肌酸酐是肌肉中肌酸的正常分解廢物，經腎臟排出到尿液中。如果腎小球的過濾功能出現問題，肌酸酐會滯留及累積在血液中，造成檢測時數值偏高。因此，可透過檢測血液肌酸酐濃度高低來評估腎功能的好壞"}<RSup value={26} />{"。"}</>
            </RText>


            <RText color={"white"} pos={[4735, 1337]} width={1349} fontSize={44} space={1} weight={300} center>
                <>{"腎小球濾過率是評估慢性腎病的一項重要指標，該數值是根據患者血液肌酸酐水平、年齡、性別、體重與種族計算出來的"}<RSup value={19} />{"。"}</>
            </RText>
            <RText color={"white"} pos={[4965, 1350]} width={1373} fontSize={44} space={1} weight={300} center>
                <>{"腎功能是以腎小球濾過率數值的多少分為五個階段"}<RSup value={20} />{"：成年人正常的估算腎小球濾過率為 > 90。"}</>
            </RText>


            <RText color={"white"} pos={[5359, 1080]} width={423} fontSize={44} space={1} weight={300} line={45} center>
                <>{"分段"}</>
            </RText>
            <RText color={"white"} pos={[5359, 1383]} width={423} fontSize={44} space={1} weight={300} line={45} center>
                <>{"描述"}</>
            </RText>
            <RText color={"white"} pos={[5304, 2124]} width={373} fontSize={37} space={1} weight={300} line={45} center>
                <>{"腎小球濾過率"}</>
                <>{"(eGFR)"}</>
                <>{"mL/min/1.73m2"}</>
            </RText>
            <RText color={"white"} pos={[5359, 2500]} width={423} fontSize={44} space={1} weight={300} line={45} center>
                <>{"腎功能%"}</>
            </RText>

            <RText color={"white"} pos={[5487, 1246]} width={273} fontSize={44} space={1} weight={300} line={149}>
                <>{"第1期(G1)"}</>
                <>{"第2期(G2)"}</>
                <>{"第3a期(G3a)"}</>
                <>{"第3b期(G3b)"}</>
                <>{"第4期(G4)"}</>
                <>{"第5期(G5)"}</>
            </RText>
            <RText color={"white"} pos={[5487, 1550]} width={523} fontSize={44} space={-1.5} weight={300} line={149}>
                <>{"有腎損傷但腎功能正常"}</>
                <>{"有腎損傷但腎功能輕微下降"}</>
                <>{"腎功能輕度至中度下降"}</>
                <>{"腎功能中度至嚴重下降"}</>
                <>{"腎功能嚴重下降"}</>
                <>{"末期腎衰竭"}</>
            </RText>
            <RText color={"white"} pos={[5487, 2110]} width={399} fontSize={44} space={1} weight={300} line={149} center>
                <>{"≥90"}</>
                <>{"60-89"}</>
                <>{"45-59"}</>
                <>{"30-44"}</>
                <>{"15-29"}</>
                <>{"<15"}</>
            </RText>
            <RText color={"white"} pos={[5487, 2539]} width={235} fontSize={44} space={1} weight={300} line={149} center>
                <>{"90-100"}</>
                <>{"60-89"}</>
                <>{"45-59"}</>
                <>{"30-44"}</>
                <>{"15-29"}</>
                <>{"<15"}</>
            </RText>


            <RText color={"white"} pos={[6875, 1136]} width={1827} fontSize={44} space={2} weight={300} center>
                {"當腎臟的過濾功能無法正常運作時，蛋白質會在尿液中出現。透過測量和計算尿液中的蛋白量（亦稱白蛋白）和肌酸酐的比值，來反映腎損傷情況，以協助驗出早期腎病。"}
            </RText>
            <RText color={"white"} pos={[7209, 1231]} width={1521} center fontSize={46} space={1} weight={300}>
                <>{"ACR的數值可分爲三個階段"}<RSup>{"19,21"}</RSup></>
            </RText>
            <RText color={"white"} pos={[7391, 2297]} width={439} center fontSize={42} line={100} space={1} weight={300}>
                {"正常至輕微增加"}
            </RText>
            <RText color={"white"} pos={[7591, 2297]} width={439} center fontSize={42} line={100} space={1} weight={300}>
                {"中度增加"}
            </RText>
            <RText color={"white"} pos={[7790, 2297]} width={439} center fontSize={42} line={100} space={1} weight={300}>
                {"顯著增加"}
            </RText>

            <RText color={"white"} pos={[7988, 1172]} width={1770} center fontSize={46} space={1} weight={300}>
                {"在上述篩查中，腎小球濾過率(eGFR)數值愈低及白蛋白／肌酸酐比值(ACR) 的數值愈高，顯示腎病愈嚴重。"}
            </RText>



            {/* <RText color={"white"} pos={[739, 1231]} width={1521} center fontSize={46} space={1} weight={300}>
                <>{"1111111111111"}<RSup value={19} />{"。"}</>
            </RText> */}


        </RBackground.PC>
        <RBackground.Mobile anchorId={props.tag} drawBoardWidth={1731}
            url={"/background/mobile/section3-fix.png"} >
            <RText color={"white"} pos={[168, 276]} width={1229} center fontSize={83} space={5}>
                {"怎麼知道有沒有慢性腎病?"}
            </RText>
            <RText color={"white"} pos={[420, 474]} width={1160} fontSize={54} space={7} weight={300}>
                <>{"多個國際醫學協會均建議有高血壓及糖尿病等人士定期進行篩查，以盡早發現慢性腎病"}<RSup value={18} />{"。"}</>
            </RText>
            <RText color={"white"} pos={[776, 471]} width={1135} fontSize={54} space={3} weight={300}>
                <>{"尿液檢查白蛋白及血液檢查血清肌酸酐水平以估算腎小球濾過率是篩查慢性腎病的有效方法 "}<RSup value={19} />{"。"}</>
            </RText>
            <RText color={"white"} pos={[1195, 317]} width={1094} center fontSize={60} space={-1} weight={300}>
                <>{"慢性腎病雙指標 ：蛋白尿 + 腎小球濾過率"}</>
            </RText>


            <RText color={"white"} pos={[1917, 640]} width={447} center fontSize={52} space={1} weight={300}>
                <>{"尿液檢驗試紙"}</>
            </RText>
            <RText color={"white"} pos={[2989, 201]} width={570} center fontSize={42} space={1} weight={300}>
                <>{"驗尿"}</>
                <>{"（尿白蛋白及肌酸酐比值）"}</>
            </RText>
            <RText color={"white"} pos={[2989, 1069]} width={408} center fontSize={44} space={1} weight={300}>
                <>{"驗血"}</>
                <>{"（血清肌酸酐檢查）"}</>
            </RText>


            <RText color={"white"} pos={[3416, 526]} width={675} center fontSize={83} space={1} weight={400}>
                {"蛋白尿檢查檢查"}
            </RText>

            <RText color={"white"} pos={[3646, 240]} width={1311} center fontSize={57} weight={300}>
                <>{"最簡單快捷的方法是用尿蛋白檢測試紙作檢查，只需將試紙進入尿液中觀察試紙顏色變化再與蛋白質標準色板比較，便可查出有否蛋白尿。"}</>
            </RText>
            <RText color={"#98cf16"} pos={[4077, 915]} width={647} fontSize={50} line={69} space={2} weight={300}>
                <>{"＞1個“+”號，代表腎功能有機會下降；異常尿蛋白“+”號越多，代表尿中白蛋白的濃度比較高"}<RSup value={25} /></>
            </RText>
            <RText color={"white"} pos={[4559, 181]} width={1369} fontSize={56} space={1} weight={300} center>
                <>{"如果持續發現有蛋白尿，就須進一步檢查血液肌酸酐水平，並確認尿白蛋白量，以檢測白蛋白／肌酸酐比值，全面了解腎功能狀況。"}</>
            </RText>


            <RText color={"white"} pos={[5206, 691]} width={877} fontSize={57} line={91} space={3} weight={300} center>
                <>{"肌酸酐是肌肉中肌酸的正常分解廢物，經腎臟排出到尿液中。如果腎小球的過濾功能出現問題，肌酸酐會滯留及累積在血液中，造成檢測時數值偏高。因此，可透過檢測血液肌酸酐濃度高低來評估腎功能的好壞"}<RSup value={26} />{"。"}</>
            </RText>

            <RText color={"white"} pos={[6225, 125]} width={1486} fontSize={53} space={2} weight={300} center>
                <>{"腎小球濾過率是評估慢性腎病的一項重要指標，該數值是根據患者血液肌酸酐水平、年齡、性別、體重與種族計算出來的"}<RSup value={19} />{"。"}</>
            </RText>
            <RText color={"white"} pos={[6498, 207]} width={1132} fontSize={55} space={1} weight={300} >
                <>{"腎功能是以腎小球濾過率數值的多少分為五個階段"}<RSup value={20} />{"：成年人正常的估算腎小球濾過率為 > 90。"}</>
            </RText>


            <RText color={"white"} pos={[6912, 38]} width={423} fontSize={48} space={1} weight={300} line={45} center>
                <>{"分段"}</>
            </RText>
            <RText color={"white"} pos={[6912, 310]} width={423} fontSize={48} space={1} weight={300} line={45} center>
                <>{"描述"}</>
            </RText>
            <RText color={"white"} pos={[6872, 924]} width={340} fontSize={37} space={1} weight={300} line={37} center>
                <>{"腎小球濾過率"}</>
                <>{"(eGFR)"}</>
                <>{"mL/min/1.73m2"}</>
            </RText>
            <RText color={"white"} pos={[6906, 1286]} width={300} fontSize={44} space={1} weight={300} line={45} center>
                <>{"腎功能%"}</>
            </RText>

            <RText color={"white"} pos={[7026, 191]} width={273} fontSize={48} space={1} weight={300} line={127}>
                <>{"第1期(G1)"}</>
                <>{"第2期(G2)"}</>
                <>{"第3a期(G3a)"}</>
                <>{"第3b期(G3b)"}</>
                <>{"第4期(G4)"}</>
                <>{"第5期(G5)"}</>
            </RText>
            <RText color={"white"} pos={[7026, 465]} width={435} fontSize={39} space={-3} weight={300} line={127}>
                <>{"有腎損傷但腎功能正常"}</>
                <>{"有腎損傷但腎功能輕微下降"}</>
                <>{"腎功能輕度至中度下降"}</>
                <>{"腎功能中度至嚴重下降"}</>
                <>{"腎功能嚴重下降"}</>
                <>{"末期腎衰竭"}</>
            </RText>
            <RText color={"white"} pos={[7026, 966]} width={273} fontSize={45} space={1} weight={300} line={127} center>
                <>{"≥90"}</>
                <>{"60-89"}</>
                <>{"45-59"}</>
                <>{"30-44"}</>
                <>{"15-29"}</>
                <>{"<15"}</>
            </RText>
            <RText color={"white"} pos={[7026, 1327]} width={273} fontSize={45} space={1} weight={300} line={127}>
                <>{"90-100"}</>
                <>{"60-89"}</>
                <>{"45-59"}</>
                <>{"30-44"}</>
                <>{"15-29"}</>
                <>{"<15"}</>
            </RText>

            <RText color={"white"} pos={[8174, 136]} width={1449} fontSize={57} line={91} space={7} weight={300} center>
                {"當腎臟的過濾功能無法正常運作時，蛋白質會在尿液中出現。透過測量和計算尿液中的蛋白量（亦稱白蛋白）和肌酸酐的比值，來反映腎損傷情況，以協助驗出早期腎病。"}
            </RText>

            <RText color={"white"} pos={[8669, 173]} width={1521} center fontSize={59} space={1} weight={300}>
                <>{"ACR的數值可分爲三個階段"}<RSup>{"19,21"}</RSup></>
            </RText>
            <RText color={"white"} pos={[8854, 1193]} width={436} center fontSize={48} line={100} space={1} weight={300}>
                {"正常至輕微增加"}
            </RText>
            <RText color={"white"} pos={[9055, 1193]} width={436} center fontSize={48} line={100} space={1} weight={300}>
                {"中度增加"}
            </RText>
            <RText color={"white"} pos={[9260, 1193]} width={436} center fontSize={48} line={100} space={1} weight={300}>
                {"顯著增加"}
            </RText>

            <RText color={"white"} pos={[9482, 163]} width={1446} center fontSize={55} space={1} weight={300}>
                {"在上述篩查中，腎小球濾過率(eGFR)數值愈低及白蛋白／肌酸酐比值(ACR) 的數值愈高，顯示腎病愈嚴重。"}
            </RText>


        </RBackground.Mobile>
    </>
}


export const Section4 = (props: { tag: string }) => {
    return <>
        <RBackground.PC anchorId={props.tag} drawBoardWidth={4001}
            url={"/background/section4-fix.png"}>
            <RText color={"black"} pos={[418, 1167]} width={1637} center fontSize={46} space={1} weight={300}>
                {"雖然慢性腎病不能治癒，但透過藥物治療及改善生活習慣可幫助緩解症狀並阻止病情惡化。"}
            </RText>
            <RText color={"black"} pos={[785, 1071]} width={1102} fontSize={45} space={1} weight={300} line={74}>
                <>{"戒烟，或控制酒精攝取量。"}</>
                <>{"維持健康均衡飲食。"}</>
                <>{"限制食鹽攝取量於每天 6 克以下（大約 1 茶匙）。"}</>
                <>{"定期運動，每周至少運動 150 分鐘。"}</>
                <>{"保持健康體重。"}</>
            </RText>
            <RText color={"black"} pos={[788, 2351]} width={927} fontSize={45} space={1} weight={300} line={74}>
                <>{"有些藥物可能令腎臟受損，如非類固醇抗炎藥，應避免使用。"}</>
                <>{"咨詢醫生意見，服用藥物控制及維持血壓和膽固醇於正常水平。"}</>

            </RText>
        </RBackground.PC>
        <RBackground.Mobile anchorId={props.tag} drawBoardWidth={1730}
            url={"/background/mobile/section4-fix.png"} >
            <RText color={"black"} pos={[418, 157]} width={1083} fontSize={55} space={0} weight={300}>
                {"雖然慢性腎病不能治癒，但透過藥物治療及改善生活習慣可幫助緩解症狀並阻止病情惡化。"}
            </RText>
            <RText color={"black"} pos={[1018, 264]} width={1343} fontSize={58} space={1} weight={300} line={90}>
                <>{"戒烟，或控制酒精攝取量。"}</>
                <>{"維持健康均衡飲食。"}</>
                <>{"限制食鹽攝取量於每天 6 克以下（大約 1 茶匙）。"}</>
                <>{"定期運動，每周至少運動 150 分鐘。"}</>
                <>{"保持健康體重。"}</>
                <>{"有些藥物可能令腎臟受損，如非類固醇抗炎藥，應避免使用。"}</>
                <>{"咨詢醫生意見，服用藥物控制及維持血壓和膽固醇於正常水平。"}</>

            </RText>
        </RBackground.Mobile>
    </>
}
export const Section5 = (props: {
    tag: string
    onCheckClicked: () => void
}) => {
    return <>
        <RBackground.PC anchorId={props.tag} url={"/background/section5.png"} drawBoardWidth={4000}  >
            <RButton pos={[2869, 1335]} size={[819, 229]} onClick={props.onCheckClicked}
                background={"/background/icon/check1.png"}
                hover={{
                    url: "/background/icon/check1-hover.png",
                    pos: [0, 0],
                }}
            />
        </RBackground.PC>

        <RBackground.Mobile anchorId={props.tag} url={"/background/mobile/section5.png"} drawBoardWidth={1730}>
            <RButton pos={[2333, 127]} size={[921, 260]} radius={"max"} onClick={props.onCheckClicked} />
        </RBackground.Mobile>

    </>
}
